import React, { useState } from 'react';
import axios from 'axios';
import { useEffect } from 'react';
import useAuth from '../../hooks/useAuth';
import './Login.css'
import logo from '../../assets/hmcLogoBlue.png';
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { endpoint, login, signup } from '../../constants/constant';
import { FiEye, FiEyeOff } from 'react-icons/fi'; // Importing i
// import { useHistory } from 'react-router-dom';
// Inside the handleLogin function


const Login = () => {
  // const history = useHistory();

  axios.defaults.withCredentials = true;
  const navigate = useNavigate();
  const { setAuth } = useAuth();
  // const { auth } = useAuth();
  const [id, setId] = useState('');
  const [password, setPassword] = useState('');
  let { state } = useLocation();

  const handleLoginSubmit = async (e) => {
    console.log(id, password);
  
    try {
      // Send login request
      const response = await axios.post(`${endpoint}${login}`, { id, password });
      if (response.data === false) {
        // Backend sends 'false' for invalid credentials
        alert("Wrong email/password. Please try again.");
        return; // Exit the function early
      }
  
      if (response.status === 200) {
        const token = response.data.accessToken;
  
        // Decode the JWT token
        const base64Url = token.split(".")[1];
        const base64 = base64Url.replace("-", "+").replace("_", "/");
        const decodedToken = JSON.parse(window.atob(base64));
  
        console.log("Decoded Token:", decodedToken);
  
        const role = decodedToken.role;
  
        // Prepare token data for authentication state
        const tokenData = {
          username: decodedToken.username,
          role: decodedToken.role,
          rawToken: token,
        };
  
        if (role) {
          console.log("Role found:", role);
          setAuth(tokenData); // Set authentication state
          navigate("/dashboard/new"); // Navigate to dashboard
        } else {
          console.log("Role not found in token");
        }
      } else {
        console.log("Unexpected response status:", response.status);
      }
    } catch (error) {
      // Handle errors
      if (error.response && error.response.status === 401) {
        // Show alert for incorrect credentials
        alert("Wrong email/password. Please try again.");
      } else {
        console.error("Error during login:", error);
      }
    }
  };
  

  const handlesignupSubmit = async (e) => {
    // e.preventDefault();
    console.log(id, password)

    try {
      //  const response = await axios.post(`${endpoint}${signup}`,{ id, password,role:'PRESIDENT' });
      // roles->>> ['CLERK','REGISTRAR','ACCOUNTANT','PRESIDENT']

    } catch (e) { console.log(e) }
  };

  const [showPassword, setShowPassword] = useState(false); // Toggles password visibility

  // // Function to handle login submission
  // const handleLoginSubmit = () => {
  //   if (id && password) {
  //     console.log('Logging in with:', { id, password });
  //     // Add login logic here
  //   } else {
  //     alert('Please fill out both fields.');
  //   }
  // };




  return (

    <div className='Login'>

<div className="loginFormContainer">
    {/* Logo Section */}
    <div className="logoLogin">
        <img src={logo} alt="Logo" />
    </div>

    {/* Login Form */}
    <div className="loginForm">
        {/* Email Input */}
        <input
            type="text"
            placeholder="Email ID"
            value={id}
            onChange={(e) => setId(e.target.value)}
        />

        {/* Password Input with Toggle */}
        <div style={{ position: 'relative' }}>
            <input
                type={showPassword ? 'text' : 'password'}
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
            />
            <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                style={{
                    marginTop: '0',
                    position: 'absolute',
                    right: '10px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    background: 'none',
                    border: 'none',
                    cursor: 'pointer',
                    fontSize: '16px',
                    color: 'black', // Set the icon color to black
                }}
                aria-label={showPassword ? 'Hide Password' : 'Show Password'}
            >
                {showPassword ? <FiEyeOff /> : <FiEye />}
            </button>
        </div>

        {/* Login Button */}
        <button onClick={handleLoginSubmit}>Log In</button>

        {/* Navigation Links */}
        <div className="signUpLink">
            Go to <Link to="/">Home</Link>
        </div>
    </div>
</div>


      <div className='loginBg'>

      </div>

    </div>
  )
}

export default Login






