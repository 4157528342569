import React, { useState } from 'react'
import '.././Dashboard.css'
// import Nav from '../Nav/Nav'
import { useEffect } from 'react'
import axios from 'axios'
import useAuth from '../../../hooks/useAuth';
import AdminNav from '../../Nav/AdminNav'
import { Link, useLocation } from 'react-router-dom';
import { jsPDF } from 'jspdf';
import { endpoint, getallforms, uploadcertificate, getformdata, getformdataandSequencenumber } from '../../../constants/constant';
import logo from '../../../assets/HMC_logo.png';
import sign from '../../../assets/signature.png';
import { Button, CircularProgress, Typography } from '@mui/material';
import { PDFDocument } from 'pdf-lib';
// import feedbackPdf from '/Offline_CME_guidelines.pdf';



const ClerkDash = () => {

    const [showNewApprovals, setShowNewApprovals] = useState(false)
    const [showPrevApprovals, setShowPrevApprovals] = useState(false)
    const [showCertificate, setShowCertificate] = useState(false)
    const [Approvals, setApprovals] = useState([])
    const { auth } = useAuth();
    const { setAuth } = useAuth();
    const location = useLocation();
    const [token, settoken] = useState()
    // console.log(auth);

    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadStatus, setUploadStatus] = useState("");
    const [showUploadCertiBtn, setshowUploadCertiBtn] = useState(false);

    // Handle file selection
    const handleFileChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            setSelectedFile(event.target.files[0]);
        }
    };
    const [loadingStates, setLoadingStates] = useState({});

    // Upload Certificate Function
    const SendCertificate = async (id) => {





        try {

            const response = await axios.post(`${endpoint}${uploadcertificate}`, { id },
                {
                    headers: {
                        // 'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${token}`
                    }
                }




            )


            if (response.data) {
                alert("Certificate has been sent successfully!");
            } else {
                alert("Failed to send the certificate. Please try again.");
            }

        } catch (error) {
            console.error("Error uploading certificate:", error);
            setUploadStatus("An error occurred while uploading.");
        }



    };


    useEffect(() => {
        console.log(auth);
        const token = auth.RawToken;

        settoken(e => e = token)

        const getformdata = async () => {

            try {

                const response = await axios.get(`${endpoint}${getallforms}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Accept': 'application/json'
                    }
                })


                console.log(response.data)
                setApprovals(response.data)

            } catch (e) { console.log(e) }
        }



        getformdata();
    }, [])


    const [viewNewApprovalentry, setviewNewApprovalentry] = useState(false);

    const openFormData = () => {
        setviewNewApprovalentry(e => !e)

    }



    const [downloadLink, setDownloadLink] = useState(null)





    const generateCertificate = async (_id) => {

        setLoadingStates((prev) => ({ ...prev, [_id]: true }));
        let formdata;

        try {
            // getformdataandSequencenumber
            const response = await axios.get(`${endpoint}${getformdataandSequencenumber}?id=${_id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json'
                }
            });
            formdata = await response?.data[0];
            console.log(formdata);

            // Extract data
            const ObserverName = formdata?.observerDetails?.name || 'N/A';
            const ObserverMobile = formdata?.observerDetails?.mobile || 'N/A';
            const ObserverDesignation = formdata?.observerDetails?.designation || 'N/A';
            let conferenceDate = formdata?.date || 'N/A';
            const location = formdata?.address || 'N/A';
            const theme = formdata?.theme?.toUpperCase() || 'N/A';
            const awardPoints = formdata?.PointsAwarded || 0;
            const secretary = formdata?.secretary || 'N/A';
            const honorarium = formdata?.isGovernmentOrganisation ? '' :
                "Rs.5000/- per day";
            const taRate = "Rs.20 per KM";
            const cmeEndDate = formdata?.cmeEndDate;
            const formattedCmeEndDate = cmeEndDate?.split('-').reverse().join('/')
            const cmeStartDate = formdata?.cmeStartDate;
            const formattedCmeStartDate = cmeStartDate?.split('-').reverse().join('/')
            const SequenceNumber = formdata?.certificateSequence;

            // Initialize jsPDF
            const doc = new jsPDF();
            const pageWidth = doc.internal.pageSize.width;
            const marginX = 20;
            const lineSpacing = 5;


            // Justify Text Function
            const justifySubject = (doc, text, x, y, width, lineHeight) => {
                // const processedText = text.replace('datesubj', conferenceDate);
                const lines = doc.splitTextToSize(text, width);
                // console.log(lines)
                lines.forEach((line, index) => {
                    const words = line.split(" ");
                    if (index === lines.length - 1 || words.length === 1) {
                        // Last line or single-word line: left-align

                        doc.setFont("times", "bold");
                        doc.text(line, x, y);
                        doc.setFont("times", "normal");


                    } else {
                        // Justify other lines
                        const totalWordsWidth = words.reduce((acc, word) => acc + doc.getTextWidth(word), 0);
                        const extraSpace = (width - totalWordsWidth) / (words.length - 1);

                        let currentX = x;
                        words.forEach((word, i) => {
                            // console.log(word)
                            const firstWord = theme.split(" ")[0];
                            const lastWord = theme.split(" ").slice(-1)[0];
                            console.log(`"${firstWord}`)
                            // console.log(`"${theme}"`)

                            if (word == `${awardPoints}`) {


                                doc.setFont("times", "bold");
                                doc.text(word, currentX, y);
                            }
                            else if (word == 'Hour(s)') {
                                doc.text(word, currentX, y);

                                doc.setFont("times", "normal");
                                // doc.text(word, currentX, y);
                            }

                            else if (word == `"${firstWord}`) {
                                // Replace "tr1" with the theme and make it bold
                                doc.setFont("times", "bold");
                                doc.text(word, currentX, y); // Insert theme content instead of "tr1"
                                // doc.setFont("times", "normal"); // Reset font after theme
                            } else if (word == `${lastWord}"`) {
                                // Replace "tr1" with the theme and make it bold

                                doc.text(word, currentX, y); // Insert theme content instead of "tr1"
                                doc.setFont("times", "normal");
                                // doc.setFont("times", "normal"); // Reset font after theme
                            } else if (
                                word === "January" || word === "February" || word === "March" || word === "April" ||
                                word === "May" || word === "June" || word === "July" || word === "August" ||
                                word === "September" || word === "October" || word === "November" || word === "December"
                            ) { doc.setFont("times", "bold"); doc.text(word, currentX, y); }


                            else {
                                doc.text(word, currentX, y);
                            }



                            currentX += doc.getTextWidth(word) + extraSpace;
                        });
                    }
                    y += lineHeight;
                });
                return y; // Return the new Y position
            };
            const justifyText = (doc, text, x, y, width, lineHeight) => {
                // const processedText = text.replace('datesubj', conferenceDate);
                const lines = doc.splitTextToSize(text, width);
                // console.log(lines)
                lines.forEach((line, index) => {
                    const words = line.split(" ");
                    if (index === lines.length - 1 || words.length === 1) {
                        // Last line or single-word line: left-align

                        // doc.setFont("times", "bold");
                        doc.text(line, x, y);
                        // doc.setFont("times", "normal");


                    } else {
                        // Justify other lines
                        const totalWordsWidth = words.reduce((acc, word) => acc + doc.getTextWidth(word), 0);
                        const extraSpace = (width - totalWordsWidth) / (words.length - 1);

                        let currentX = x;
                        words.forEach((word, i) => {
                            // console.log(word)
                            const firstWord = theme.split(" ")[0];
                            const lastWord = theme.split(" ").slice(-1)[0];
                            // console.log(`"${firstWord}`)
                            // console.log(`"${theme}"`)


                            if (word == 'appoints') {

                                doc.text(word, currentX, y);

                                doc.setFont("times", "italic");

                            }
                            else if (word == 'HMC') {
                                doc.setFont("times", "normal");

                                doc.text(word, currentX, y);


                            }




                            else {
                                doc.text(word, currentX, y);
                            }



                            currentX += doc.getTextWidth(word) + extraSpace;
                        });
                    }
                    y += lineHeight;
                });
                return y; // Return the new Y position
            };

            // Add Logo
            const imgWidth = 30, imgHeight = 30;
            doc.addImage(logo, "PNG", marginX, 10, imgWidth, imgHeight);




            // Header Section
            doc.setFont("times", "bold");
            doc.setFontSize(17);
            doc.text("HARYANA MEDICAL COUNCIL", pageWidth / 2, 20, { align: "center" });

            doc.setFontSize(11);
            doc.setFont("times", "normal");
            doc.text("SCO-410, 2nd floor, Sector - 20, Panchkula, Haryana - 134116", pageWidth / 2, 28, { align: "center" });
            doc.text("Email - registrarhmc@gmail.com, Office - 0172 - 2520165", pageWidth / 2, 34, { align: "center" });

            // Horizontal Line
            doc.line(marginX, 42, pageWidth - marginX, 42);

            // Reference and Date
            doc.setFont("times", "bold");
            doc.setFontSize(12);
            const currentYear = new Date().getFullYear();
            doc.text(`HMC/${currentYear}/${SequenceNumber}`, marginX, 50);
            const currentDate = new Date();
            const formattedDate = `${String(currentDate.getDate()).padStart(2, '0')}/${String(currentDate.getMonth() + 1).padStart(2, '0')}/${currentDate.getFullYear()}`;
            doc.text(`Dated: ${formattedDate}`, pageWidth - 54, 50);

            // Recipient
            let currentY = 58;
            doc.setFont("times", "normal");
            doc.text("To,", marginX, currentY);
            doc.text(`                   ${secretary},`, marginX + 4, currentY += lineSpacing * 0.8);
            doc.text(`                   Organizing Secretary,`, marginX + 4, currentY += lineSpacing * 0.8);

            // Subject
            currentY += lineSpacing * 1.4;
            doc.setFont("Helvetica", "bold");
            doc.text("Subject:", marginX, currentY);

            doc.text(`Regarding Grant of Credit Hour's for the Conference/CME to be held on `, marginX + 24, currentY);
            doc.text(`${formattedCmeStartDate} to ${formattedCmeEndDate} at ${location}.`, marginX + 24, currentY + 6);

            // Main Body
            doc.setFont("times", "normal");

            currentY += lineSpacing * 2.8;
            const firstPara = `               With reference to your application cited above, it is to inform you that the Haryana Medical Council has considered your proposal and grant to award ${awardPoints} Credit Hour(s) for the CME/Workshop/Conference/Seminar/Training for "${theme}"  to be held on ${formattedCmeStartDate} to ${formattedCmeEndDate} at ${location}.`;
            currentY = justifySubject(doc, firstPara, marginX, currentY, pageWidth - marginX * 2, lineSpacing);

            currentY += lineSpacing;
            const boldNote = `                   The Haryana Medical Council appoints ${ObserverName}, ${ObserverDesignation} as the Observer. "The Observer shall be provided a place on the Dias during the inauguration and his/her Honorarium (${honorarium}), T.A. (${taRate} for own vehicle or as per actual taxi bill) and Accommodation shall be met by your organization/association. Observer shall be one of the signatories of the CME/ Workshop/ Conference/ Seminar/ Training Certificate. However, the accreditation granted shall be liable to be terminated at any time, if the information furnished by the association/ organization is found false or the Haryana Medical Council Appointed Observer finds any discrepancy or violation of guidelines. The association/ organization could be debarred from future accreditation in such a case."`;
            currentY = justifyText(doc, boldNote, marginX, currentY, pageWidth - marginX * 2, lineSpacing);

            currentY += lineSpacing;
            const observerNote = `                   The Organizer shall submit the CME Observer Feedback Form (Annexure-III) to the undersigned immediately after completion of the programme along with name of all delegates & faculties/Speakers with their MCI/ State Medical Council Registration Number, telephone number, address and subjects covered by Faculties/ Speaker with duration for record of this office which is mandatory.`;
            currentY = justifyText(doc, observerNote, marginX, currentY, pageWidth - marginX * 2, lineSpacing);

            currentY += lineSpacing;
            doc.setFont("times", "bold");
            doc.setFontSize(8);

            doc.text("NOTE:", marginX, currentY);
            currentY += 4;

            const noteone = "1.  KINDLY CONTACT THE OBSERVER IMMEDIATELY AFTER RECEIPT OF THIS LETTER & SHARE ALL THE DETAILS OF CME/CONFERENCE AND MAKE ARRANGEMENTS FOR STAY, TRANSPORT ACCORDINGLY";
            currentY = justifyText(doc, noteone, marginX, currentY, pageWidth - marginX * 2, 4);

            const notetwo = "2.  THE ORGANIZING CHAIRMAN/SECRETARY MAY ALSO ENSURE THAT THE CREDIT HOURS CERTIFICATES WILL BE GIVEN TO DELEGATES AS PER THE DAY ATTENDANCE.";

            currentY = justifyText(doc, notetwo, marginX, currentY, pageWidth - marginX * 2, 4);




            currentY += 6;
            doc.setFont("times", "normal");

            doc.setFontSize(12);
            doc.text("With Regards,", pageWidth - 70, currentY);

            // Add Logo
            const signWidth = 20, signHeight = 20;
            doc.addImage(sign, "PNG", pageWidth - 70, currentY - 2, signWidth, signHeight);


            doc.text("Registrar", pageWidth - 70, currentY += lineSpacing * 2.7);
            doc.text("Haryana Medical Council", pageWidth - 70, currentY += lineSpacing * 0.8);
            currentY += 8;


            doc.setFont("times", "bold");
            doc.setFontSize(12);
            doc.text(`HMC/${currentYear}/${SequenceNumber}`, marginX, currentY);
            doc.text(`Dated: ${formattedDate}`, pageWidth - 70, currentY);


            currentY += 8;
            doc.setFont("times", "normal");

            const endNote = `Copy is forwarded to ${ObserverName}, ${ObserverDesignation} for information & further necessary action please. (MOBILE - ${ObserverMobile})`;
            currentY = justifyText(doc, endNote, marginX, currentY, pageWidth - marginX * 2, lineSpacing);

            // Footer
            currentY += 4;



            doc.text("With Regards,", pageWidth - 70, currentY);


            // Add Logo
            doc.addImage(sign, "PNG", pageWidth - 70, currentY - 2, signWidth, signHeight);
            doc.text("Registrar,", pageWidth - 70, currentY += lineSpacing * 2.4);
            doc.text("Haryana Medical Council", pageWidth - 70, currentY += lineSpacing * 0.8);

            // Save PDF
            const pdfData = doc.output("arraybuffer");
            // const pdfData = doc.output("blob");

            const feedbackPdf = '/Offline_CME_guidelines.pdf';


            // Load your feedback form PDF using pdf-lib
            const feedbackPdfBytes = await fetch(feedbackPdf).then(res => res.arrayBuffer()); // Fetch the PDF
            const feedbackPdfDoc = await PDFDocument.load(feedbackPdfBytes);

            // Load the dynamic PDF generated by jsPDF
            const dynamicPdfDoc = await PDFDocument.load(pdfData);

            // Copy the page(s) from the feedback form
            const copiedPages = await dynamicPdfDoc.copyPages(feedbackPdfDoc, [0]);
            const feedbackPage = copiedPages[0];



            // Add the feedback form as the last page

            dynamicPdfDoc.addPage(feedbackPage);

            // Serialize the merged PDF
            const mergedPdfBytes = await dynamicPdfDoc.save();

            // Convert to Blob for sending to backend
            const formData = new FormData();
            const blob = new Blob([mergedPdfBytes], { type: 'application/pdf' });
            formData.append("Ack", blob);
            formData.append("CRN", formdata.CRN);

            // const formData = new FormData();
            // formData.append("Ack", pdfData); // Append the item ID
            // formData.append("CRN", formdata.CRN); // Append the item ID
            // doc.save("certificate.pdf");
            try {
                const response = await axios.post(`${endpoint}/savecertificate`, formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': `Bearer ${token}`
                        }
                    }
                )
                if (response?.data?.FinalCertificateURL?.url) {
                    setLoadingStates((prev) => ({ ...prev, [_id]: false }));
                    // setUploading5(false);
                    console.log(response?.data?.FinalCertificateURL?.url)

                    setApprovals((prevApprovals) =>
                        prevApprovals.map((approval) =>
                            approval._id === _id
                                ? {
                                    ...approval,
                                    FinalCertificateURL: {
                                        ...approval.FinalCertificateURL, // Ensure existing properties are retained
                                        url: response.data.FinalCertificateURL.url,
                                    },
                                }
                                : approval
                        )
                    );

                    // setDownloadButton(true)
                    // setDownloadLink(`${response.data.AckURL}`)
                    // setDownloadLink(response?.data?.FinalCertificateURL?.url)
                } else {

                }
            } catch (error) {
                console.error("Error uploading pdf:", error);
            }
        } catch (e) {
            console.log(e);
        }


    };





    const click = () => {
        console.log('clicked')
    }

    useEffect(() => {
        setShowNewApprovals(false);
        setShowPrevApprovals(false);
        setShowCertificate(false);
        // Extract the path after "/dashboard/"
        const pathAfterDashboard = location.pathname.split("/dashboard/")[1];

        if (pathAfterDashboard === "new") {
            setShowNewApprovals(true);
        } else if (pathAfterDashboard === "previous") {
            setShowPrevApprovals(true);
        } else if (pathAfterDashboard === 'certificategeneration') {
            setShowCertificate(true);
        }
    }, [location.pathname]);

    const [searchCaseId, setSearchCaseId] = useState("");

    const [paymentFilter, setPaymentFilter] = useState('Success');


    const [uploading5, setUploading5] = useState(false);
    const [activeFilter, setActiveFilter] = useState('Success');

    const handleToggle = (filter) => {
        setActiveFilter(filter);
        setPaymentFilter(filter);
    };


    return (
        <div className=''>
            <AdminNav />

            <div className='Dashboard'>

                <div className="sideBar">
                    <Link
                        to={{
                            pathname: "/dashboard/new",
                            state: { from: location.pathname },
                        }}
                        className={location.pathname === "/dashboard/new" ? "selected sidebarItem" : "sidebarItem"}
                    >
                        New Applications
                    </Link>

                    <Link
                        to={{
                            pathname: "/dashboard/previous",
                            state: { from: location.pathname },
                        }}
                        className={location.pathname === "/dashboard/previous" ? "selected sidebarItem" : "sidebarItem"}
                    >
                        Previously Approved
                    </Link>

                    <Link
                        to={{
                            pathname: "/dashboard/certificategeneration",
                            state: { from: location.pathname },
                        }}
                        className={
                            location.pathname === "/dashboard/certificategeneration"
                                ? "selected sidebarItem"
                                : "sidebarItem"
                        }
                    >
                        Certificate Generation
                    </Link>
                </div>


                <div className='mainBar'>
                    <div className='dashMainHeading'>Initiator Dashboard</div>

                    <div className="searchBox">
                        <input
                            type="text"
                            placeholder="Search by Case ID..."
                            value={searchCaseId}
                            onChange={(e) => setSearchCaseId(e.target.value)}
                            className="searchInput"
                        />
                    </div>
                    {/* {!viewNewApprovalentry?         */}
                    {/* {showNewApprovals ?
                        <>
                            <div className='dashHeading'>New Applications</div>
                            <div style={{ marginBottom: '1rem', display: 'flex', gap: '0.5rem' }}>
                                <button
                                    onClick={() => handleToggle('success')}
                                    style={{
                                        padding: '0.5rem 1rem',
                                        border: '1px solid #008000',
                                        backgroundColor: activeFilter === 'success' ? '#008000' : 'white',
                                        color: activeFilter === 'success' ? 'white' : '#008000',
                                        cursor: 'pointer',
                                        borderRadius: '4px',
                                    }}
                                >
                                    Payment Success
                                </button>
                                <button
                                    onClick={() => handleToggle('failure')}
                                    style={{
                                        padding: '0.5rem 1rem',
                                        border: '1px solid rgb(255, 0, 0)',
                                        backgroundColor: activeFilter === 'failure' ? 'rgb(255, 0, 0)' : 'white',
                                        color: activeFilter === 'failure' ? 'white' : 'rgb(255, 0, 0)',
                                        cursor: 'pointer',
                                        borderRadius: '4px',
                                    }}
                                >
                                    Payment Failure
                                </button>
                            </div>
                            <div className='approvalsContainer'>

                                {Approvals && Approvals.length > 0 ? (
                                    Approvals
                                        .filter(
                                            (item) =>
                                                item.approvals.clerk === false &&
                                                item.CRN.toLowerCase().includes(searchCaseId.toLowerCase()) &&
                                                item?.paymentData?.RMK === paymentFilter   // Additional filter condition
                                        )
                                        .map((item, i) => (
                                            <div key={i} className="approval">
                                                <div className="approvalContent">
                                                    <div>
                                                        <strong>Organization Name:</strong> {item.organizationName}
                                                    </div>
                                                    <div>
                                                        <strong>Case ID:</strong> {item.CRN.replace(/^(HMC)(CME)/, "$1/$2/")}
                                                    </div>
                                                    <div>
                                                        <strong>Theme:</strong> {item.theme}
                                                    </div>
                                                    <div>
                                                        <strong>Dates:</strong> {item.cmeStartDate} to {item.cmeEndDate}
                                                    </div>
                                                </div>

                                                <Link to={`/dashboard/clerk/${item._id}`} key={item._id}>
                                                    <button>View</button>
                                                </Link>
                                            </div>
                                        ))
                                ) : (
                                    <div>No records available</div>
                                )}


                            </div>

                        </>

                        :
                        null
                    } */}

                    {showNewApprovals ? (
                        <>
                            <div className="dashHeading">New Applications</div>
                            <div style={{ marginBottom: '1rem', display: 'flex', gap: '0.5rem' }}>
                                <button
                                    onClick={() => handleToggle('Success')}
                                    style={{
                                        padding: '0.5rem 1rem',
                                        border: '1px solid #008000',
                                        backgroundColor: activeFilter === 'Success' ? '#008000' : 'white',
                                        color: activeFilter === 'Success' ? 'white' : '#008000',
                                        cursor: 'pointer',
                                        borderRadius: '4px',
                                    }}
                                >
                                    Payment Success
                                </button>
                                <button
                                    onClick={() => handleToggle('failure')}
                                    style={{
                                        padding: '0.5rem 1rem',
                                        border: '1px solid rgb(255, 0, 0)',
                                        backgroundColor: activeFilter === 'failure' ? 'rgb(255, 0, 0)' : 'white',
                                        color: activeFilter === 'failure' ? 'white' : 'rgb(255, 0, 0)',
                                        cursor: 'pointer',
                                        borderRadius: '4px',
                                    }}
                                >
                                    Payment Failure
                                </button>
                            </div>
                            <div className="approvalsContainer">
                                {Approvals && Approvals.length > 0 ? (
                                    Approvals.filter(
                                        (item) =>
                                            item.approvals.clerk === false &&
                                            item.CRN.toLowerCase().includes(searchCaseId.toLowerCase()) &&
                                            (
                                                (item?.paymentData?.RMK === paymentFilter) ||
                                                (paymentFilter === 'failure' && item?.paymentData?.status === false) // Include `false` in failure group
                                            )
                                    ).map((item, i) => (
                                        <div key={i} className="approval">
                                            <div className="approvalContent">
                                                <div>
                                                    <strong>Organization Name:</strong> {item.organizationName}
                                                </div>
                                                <div>
                                                    <strong>Case ID:</strong> {item.CRN.replace(/^(HMC)(CME)/, "$1/$2/")}
                                                </div>
                                                <div>
                                                    <strong>Theme:</strong> {item.theme}
                                                </div>
                                                <div>
                                                    <strong>Dates:</strong> {item.cmeStartDate} to {item.cmeEndDate}
                                                </div>
                                            </div>

                                            <Link to={`/dashboard/clerk/${item._id}`} key={item._id}>
                                                <button>View</button>
                                            </Link>
                                        </div>
                                    ))
                                ) : (
                                    <div>No records available</div>
                                )}
                            </div>
                        </>
                    ) : null}


                    {showPrevApprovals ?
                        <>
                            <div className='dashHeading'>Previous Approvals</div>

                            <div className='approvalsContainer'>

                                {Approvals && Approvals.length > 0 ? (
                                    Approvals.filter(
                                        (item) =>
                                            item.approvals.clerk === true &&
                                            item.CRN.toLowerCase().includes(searchCaseId.toLowerCase()) // Filtering by Case ID
                                    ).map((item, i) => (
                                        <div key={i} className="approval">
                                            <div className="approvalContent">
                                                <div>
                                                    <strong>Organization Name:</strong> {item.organizationName}
                                                </div>
                                                <div>
                                                    <strong>Case ID:</strong>{" "}
                                                    {item.CRN.replace(/^(HMC)(CME)/, "$1/$2/")}
                                                </div>
                                                <div>
                                                    <strong>Theme:</strong> {item.theme}
                                                </div>
                                                <div>
                                                    <strong>Dates:</strong> {item.cmeStartDate} to{" "}
                                                    {item.cmeEndDate}
                                                </div>
                                            </div>

                                            <Link to={`/dashboard/clerk/${item._id}`} key={item._id}>
                                                <button>View</button>
                                            </Link>
                                        </div>
                                    ))
                                ) : (
                                    <div>No records available</div>
                                )}


                            </div>
                        </>
                        : null
                    }

                    {showCertificate ? (
                        <>
                            <div className="dashHeading">Pending Certificates</div>



                            <div className="approvalsContainer">
                                {Approvals && Approvals.length > 0 ? (
                                    Approvals.filter(
                                        (item) =>
                                            item.approvals.clerk === true &&
                                            item.approvals.registrar === true &&
                                            item.approvals.accountant === true &&
                                            item.approvals.president === true &&
                                            item.CRN.toLowerCase().includes(searchCaseId.toLowerCase()) // Filtering by Case ID
                                    ).map((item, i) => (
                                        <div key={i} className="approval">
                                            <div className="approvalContent">
                                                <div>
                                                    <strong>Organization Name:</strong>{" "}
                                                    {item.organizationName}
                                                </div>
                                                <div>
                                                    <strong>Case ID:</strong> {item.CRN}
                                                </div>
                                                <div>
                                                    <strong>Theme:</strong> {item.theme}
                                                </div>
                                                <div>
                                                    <strong>Dates:</strong> {item.cmeStartDate} to{" "}
                                                    {item.cmeEndDate}
                                                </div>
                                            </div>

                                            {item?.FinalCertificateURL?.url ? (
                                                <div>

                                                    <button
                                                        onClick={() => generateCertificate(item._id)}
                                                        className="approveBtn"
                                                        style={{ marginTop: '24px' }}
                                                    >
                                                        Generate Again
                                                    </button>
                                                    <div style={{ minHeight: "24px", display: "flex", alignItems: "center" }}>
                                                        {loadingStates[item._id] && <CircularProgress size={24} />}
                                                        {!loadingStates[item._id] ? (
                                                            <Typography variant="body2" color="success.main">
                                                                Generated successfully!
                                                            </Typography>
                                                        ) : (
                                                            < >

                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            ) : (
                                                <button
                                                    onClick={() => generateCertificate(item._id)}
                                                    className="approveBtn"
                                                >
                                                    Generate Certificate
                                                </button>
                                            )}

                                            {item?.FinalCertificateURL?.url ? (
                                                <>
                                                    <button
                                                        onClick={() => window.open(item?.FinalCertificateURL?.url, '_blank')}
                                                        className="approveBtn"
                                                    >
                                                        View
                                                    </button>
                                                    <button
                                                        onClick={() => SendCertificate(item._id)}
                                                        className="approveBtn"
                                                    >
                                                        Send
                                                    </button>
                                                </>
                                            ) : (
                                                <>
                                                    {/* Add other elements here if needed */}
                                                </>
                                            )}



                                            {/* {showUploadCertiBtn && (
                                                <div className="ShowUploadCertiModalContainer">
                                                    <div className="ShowUploadCertiModal">
                                                        <div>
                                                            <div
                                                                className="closeShowUploadCertiModal"
                                                                onClick={() =>
                                                                    setshowUploadCertiBtn(false)
                                                                }
                                                            >
                                                                ×
                                                            </div>
                                                            <div className="ShowUploadCertiHead">
                                                                Upload Certificate
                                                            </div>
                                                        </div>
                                                        <input
                                                            type="file"
                                                            accept="application/pdf"
                                                            onChange={handleFileChange}
                                                            className="uploadCertiInput"
                                                        />
                                                        <button
                                                            onClick={() =>
                                                                uploadCertificate(item._id)
                                                            }
                                                            disabled={!selectedFile}
                                                            className="approveBtn"
                                                        >
                                                            Upload Certificate
                                                        </button>
                                                        {uploadStatus && <p>{uploadStatus}</p>}
                                                    </div>
                                                </div>
                                            )} */}
                                        </div>
                                    ))
                                ) : (
                                    <div>No records available</div>
                                )}
                            </div>
                        </>
                    ) : null}





                </div>
            </div>
        </div>
    )
}

export default ClerkDash