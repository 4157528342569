import React, { useState } from 'react'
import '.././Dashboard.css'
// import Nav from '../Nav/Nav'
import { useEffect } from 'react'
import axios from 'axios'
import useAuth from '../../../hooks/useAuth';
import AdminNav from '../../Nav/AdminNav'
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';
import { docapproval, docapprovalall, endpoint, getformdata, } from '../../../constants/constant';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


const predefinedLabels = [
    'Registration Number of Association / Organization with evidence',
    'List of the Faculty with detailed qualification and experience',
    'Detailed programme schedule mentioning time, subject and the name of the Speaker',
    'List of Topics',
    'Medical Registration Certificate of the Organizing Chairman',
    'Medical Registration Certificate of the Organizing Secretary',
    'Any Other Supporting Documents'
];



const ClerkApprovalPage = () => {


    const [objectionText, setObjectionText] = useState("");
    //this page is used for form approvals
    const [showNewApprovals, setShowNewApprovals] = useState(true)
    const [showCertificate, setShowCertificate] = useState(false)
    const [showPrevApprovals, setShowPrevApprovals] = useState(false)
    const [Approvals, setApprovals] = useState([])
    const { auth } = useAuth();
    const { setAuth } = useAuth();
    const [formData, setformData] = useState({})
    const [token, settoken] = useState()
    // console.log(auth);
    const navigate = useNavigate();
    const location = useLocation();
    const previousUrl = location.state?.from || "No previous URL";
    const { _id } = useParams()
    const [showModal, setShowModal] = useState(false);
    const [selectedLabel, setselectedLabel] = useState('')
    // var token='';

    useEffect(() => {
        console.log(auth);
        const tokendata = auth.RawToken;

        settoken(e => e = tokendata)



        const handle_getformdata = async () => {



            try {

                const response = await axios.get(`${endpoint}${getformdata}?id=${_id}`, {
                    headers: {
                        'Authorization': `Bearer ${tokendata}`,
                        'Accept': 'application/json'
                    }
                })
                console.log(response.data)
                const fd = response.data;
                // console.log(fd[0].chairman)
                setformData(e => fd[0])
                console.log(formData)
                // setApprovals(response.data)

            } catch (e) { console.log(e) }
        }



        handle_getformdata();

        console.log(previousUrl)
    }, [])


    const [viewNewApprovalentry, setviewNewApprovalentry] = useState(false);

    const openFormData = () => {
        setviewNewApprovalentry(e => !e)

    }

    const handleapprove = async (e) => {

        console.log(token);
        console.log(e.target.id);
        const pdfid = e.target.id;

        try {
            const response = await axios.post(
                `${endpoint}${docapproval}`,
                { _id, pdfid },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`, // Replace with your actual token

                    }
                }
            );

            console.log(response.data)
            setformData(response.data);
            // const fd = response.data;
            // // console.log(fd[0].chairman)
            // setformData(e => fd[0])
            // console.log(formData)
            // setApprovals(response.data)

        } catch (e) { console.log(e) }
    }

    const handleapproveall = async () => {


        try {
            const response = await axios.post(
                `${endpoint}${docapprovalall}`,
                { _id },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`, // Replace with your actual token

                    }
                }
            );
            console.log(response.data)
            const fd = response.data;
            // console.log(fd[0].chairman)
            setformData(response.data)
            // console.log(formData)
            // setApprovals(response.data)

        } catch (e) { console.log(e) }


    }


    useEffect(() => {
        if (showModal) {
            document.body.style.overflow = "hidden"; // Disable scrolling
        } else {
            document.body.style.overflow = "auto"; // Enable scrolling
        }

        // Cleanup when the component unmounts
        return () => {
            document.body.style.overflow = "auto";
        };
    }, [showModal]);


    const handleSendObjection = async () => {
        console.log(selectedLabel, _id,objectionText)
        try {
            const response = await axios.post(
                `${endpoint}/raisedocquery`,
                { _id, selectedLabel,objectionText },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`, // Replace with your actual token
                    },
                }
            );

            console.log(response.data); // Log the response for debugging

            // Handle the response or show success message
            if (response.status === 200) {
                setObjectionText(""); // Clear the objection text
                // Close the modal by updating the state (assuming `setShowModal` controls the modal visibility)
                setShowModal(false);
                
                // Display a success alert
                alert("Objection raised successfully");
                
                // Example of handling successful response
                console.log('Objection raised successfully');
            }
        } catch (error) {
            console.error('Error while raising objection:', error);
            // Handle error (show error message to user, etc.)
        }
    }

    const handleInputChange = (e) => {
        setObjectionText(e.target.value); // Update state with the textarea value
    };

    return (
        <div className="adminDashboard">
            <AdminNav />

            <div className="dashboardWrapper">
                <div className="dashboardHeader">
                    <h1 className="dashboardTitle">Initiator Dashboard</h1>
                </div>

                <div className="dashboardContent">
                    <div className="dashboardNav">
                        <div className="dashboardHeading">FORM DATA</div>
                        <div className="backButton" onClick={() => navigate(-1)}>
                            <ArrowBackIcon className="backIcon" />
                            Back to All Records
                        </div>
                    </div>

                    <div className="formDetailsSection">
                        <div className="formDetailsGrid">
                            <div className="formDetailsRow">
                                <strong>Organization Name: </strong>{formData?.organizationName}
                            </div>
                            <div className="formDetailsRow">
                                <strong>Email: </strong>{formData?.email}
                            </div>
                            <div className="formDetailsRow">
                                <strong>Address: </strong>{formData?.address}
                            </div>
                            <div className="formDetailsRow">
                                <strong>Theme: </strong>{formData?.theme}
                            </div>
                            <div className="formDetailsRow">
                                <strong>Registration Number: </strong>{formData?.registrationNumber}
                            </div>
                            <div className="formDetailsRow">
                                <strong>CME Start Date: </strong>{formData?.cmeStartDate}
                            </div>
                            <div className="formDetailsRow">
                                <strong>CME End Date: </strong>{formData?.cmeEndDate}
                            </div>
                            <div className="formDetailsRow">
                                <strong>No of Days: </strong>{formData?.daysDifference}
                            </div>
                            <div className="formDetailsRow">
                                <strong>Faculty 1: </strong>{formData?.faculty1}
                            </div>
                            <div className="formDetailsRow">
                                <strong>No of Delegates: </strong>{formData?.delegates}
                            </div>
                            <div className="formDetailsRow">
                                <strong>No of Exercise: </strong>{formData?.exercise}
                            </div>
                            <div className="formDetailsRow">
                                <strong>No of Hours: </strong>{formData?.hours}
                            </div>
                            <div className="formDetailsRow">
                                <strong>Chairman: </strong>{formData?.chairman}
                            </div>
                            <div className="formDetailsRow">
                                <strong>Secretary: </strong>{formData?.secretary}
                            </div>
                          
                        </div>

                        <div className="documentsSection">
                            <h3 className="sectionTitle">List of Documents:</h3>
                            <div className="documentsList">
                                {predefinedLabels?.map((label, index) => {
                                    const matchingPDF = formData?.pdfURL?.find(pdf => pdf.PDF_Label === label);

                                    return (
                                        <div key={index} className="documentRow">
                                            <div className="documentIndex">{index + 1}</div>
                                            <div className="documentLabel">{label}</div>

                                            {matchingPDF && matchingPDF.url ? (
                                                <>
                                                    <button style={{
                                                        backgroundColor: "red",
                                                        color: "white",
                                                        border: "none",
                                                        padding: "10px 15px",
                                                        borderRadius: "5px",
                                                        cursor: "pointer",
                                                    }}
                                                        onClick={() => { setShowModal(true); setselectedLabel(label) } // Open modal
                                                        }> Objection</button>
                                                    <a href={matchingPDF.url} className="viewButton" target="_blank" rel="noopener noreferrer">
                                                        <button className="viewButton">View</button>
                                                    </a>
                                                    {!matchingPDF.approved ? (
                                                        <div className="approveButtonContainer">
                                                            <button
                                                                className="approveButton"
                                                                id={matchingPDF.fileName}
                                                                onClick={(e) => handleapprove(e)}
                                                            >
                                                                Approve
                                                            </button>
                                                        </div>
                                                    ) : (
                                                       <></>
                                                    )}
                                                </>
                                            ) : (
                                                <>
                                                    <div className="emptyButton"></div>
                                                    <div className="emptyButton"></div>
                                                </>
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>

                        {formData?.approvals?.clerk ? (
                            <div className="approvalLabel">You have already Approved!</div>
                        ) : (
                            <button className="approveAllButton" onClick={handleapproveall}>
                                Approve All
                            </button>
                        )}


                        {showModal && (
                            <div
                                style={{
                                    position: "fixed",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                    backgroundColor: "white",
                                    border: "1px solid #ccc",
                                    borderRadius: "10px",
                                    width: "300px",
                                    padding: "20px",
                                    zIndex: 1000,
                                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                                }}
                            >
                                <h3 style={{ marginBottom: "15px" }}>Submit Objection</h3>
                                <textarea
                                    value={objectionText} // Bind the textarea value to the state variable
                                    onChange={handleInputChange} // Update the state on input change
                                    rows="5"
                                    style={{
                                        width: "80%",
                                        padding: "10px",
                                        border: "1px solid #ccc",
                                        borderRadius: "5px",
                                        marginBottom: "15px",
                                    }}
                                    placeholder="Write your objection here..."

                                ></textarea>
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <button
                                        style={{
                                            backgroundColor: "gray",
                                            color: "white",
                                            border: "none",
                                            padding: "8px 15px",
                                            borderRadius: "5px",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => setShowModal(false)} // Close modal
                                    >
                                        Close
                                    </button>
                                    <button
                                        style={{
                                            backgroundColor: "green",
                                            color: "white",
                                            border: "none",
                                            padding: "8px 15px",
                                            borderRadius: "5px",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => { handleSendObjection() }} // Handle send
                                    >
                                        Send
                                    </button>
                                </div>
                            </div>
                        )}


                        <div className="approvalStatusSection">
                            <h3 className="sectionTitle">Approval Status:</h3>
                            <div className="statusGrid">
                            <div className="statusRow">
                                    <strong>Clerk: </strong>
                                    {formData?.approvals?.clerk ? (
                                        <span className="statusApproved">approved</span>
                                    ) : (
                                        <span className="statusNotApproved">not yet approved</span>
                                    )}
                                </div>
                                <div className="statusRow">
                                    <strong>Accountant: </strong>
                                    {formData?.approvals?.accountant ? (
                                        <span className="statusApproved">approved</span>
                                    ) : (
                                        <span className="statusNotApproved">not yet approved</span>
                                    )}
                                </div>
                               
                                <div className="statusRow">
                                    <strong>Registrar: </strong>
                                    {formData?.approvals?.registrar ? (
                                        <span className="statusApproved">approved</span>
                                    ) : (
                                        <span className="statusNotApproved">not yet approved</span>
                                    )}
                                </div>
                                <div className="statusRow">
                                    <strong>President: </strong>
                                    {formData?.approvals?.president ? (
                                        <span className="statusApproved">approved</span>
                                    ) : (
                                        <span className="statusNotApproved">not yet approved</span>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );



    // return (
    //     <div className=''>
    //         <AdminNav />

    //         <div className='Dashboard'>


    //             <div className='mainBar approvalPage'>
    //                 <div className='dashMainHeading'>Clerk Dashboard</div>

    //                 <div className='dashHeadingFlex'>
    //                     <div className='dashHeading'>FORM DATA</div>
    //                     {/* <Link to='/dashboard/new' className='backBtn'>
    //                         Back to All Records
    //                     </Link> */}
    //                     <div className='backBtn' onClick={() => navigate(-1)}>
    //                         <ArrowBackIcon/>
    //                         Back to All Records
    //                     </div>
    //                 </div>

    //                 <div className='formDataDiv'>

    //                     <div className='formDatagrid'>
    //                         <div><strong>Organization Name: </strong>{formData?.organizationName}</div>
    //                         <div><strong>Email: </strong>{formData?.email}</div>
    //                         <div><strong>Address: </strong>{formData?.address}</div>
    //                         <div><strong>Theme: </strong>{formData?.theme}</div>
    //                         <div><strong>Registration Number: </strong>{formData?.registrationNumber}</div>
    //                         <div><strong>CME Start Date: </strong>{formData?.cmeStartDate}</div>
    //                         <div><strong>CME End Date: </strong>{formData?.cmeEndDate}</div>
    //                         <div><strong>No of Days: </strong>{formData?.daysDifference}</div>
    //                         <div><strong>Faculty 1: </strong>{formData?.faculty1}</div>
    //                         <div><strong>No of Delegates: </strong>{formData?.delegates}</div>
    //                         <div><strong>No of Exercise: </strong>{formData?.exercise}</div>
    //                         <div><strong>No of Hours: </strong>{formData?.hours}</div>
    //                         <div><strong>Chairman: </strong>{formData?.chairman}</div>
    //                         <div><strong>Secretary: </strong>{formData?.secretary}</div>
    //                     </div>

    //                     <div className='pdfHead'>
    //                         List of Documents:
    //                     </div>

    //                     <div>
    //                         {predefinedLabels?.map((label, index) => {
    //                             // Check if the label from predefinedLabels matches the PDF_Label in formData.pdfURL
    //                             const matchingPDF = formData?.pdfURL?.find(pdf => pdf.PDF_Label === label);

    //                             return (
    //                                 <div key={index} className='viewPDFflex'>
    //                                     <div className='viewpdfSno'>
    //                                         {index + 1}
    //                                     </div>

    //                                     {/* Label display */}
    //                                     <div className='viewpdfLabel'>
    //                                         {label}
    //                                     </div>

    //                                     {/* View and Approve buttons if a match is found */}
    //                                     {matchingPDF && matchingPDF.url ? (
    //                                         <>
    //                                             {/* View button */}
    //                                             <a href={matchingPDF.url} className="viewPDFviewBtn" target="_blank" rel="noopener noreferrer">
    //                                                 <button>View</button>
    //                                             </a>

    //                                             {/* Approve button */}
    //                                             {!matchingPDF.approved ? (
    //                                                 <div className='viewPDFviewBtn'>
    //                                                     <button
    //                                                         className='approveBtn'
    //                                                         id={matchingPDF.fileName}
    //                                                         onClick={(e) => handleapprove(e)}
    //                                                     >
    //                                                         Approve
    //                                                     </button>
    //                                                 </div>
    //                                             ) : (
    //                                                 <div className='viewPDFviewBtn'>
    //                                                     <button
    //                                                         className='approveBtn approved'
    //                                                     >
    //                                                         Approved
    //                                                     </button>
    //                                                 </div>
    //                                             )}
    //                                         </>
    //                                     ) : (
    //                                         <>
    //                                             <div className='viewPDFviewBtn'></div>
    //                                             <div className='viewPDFviewBtn'></div>
    //                                         </>
    //                                     )}
    //                                 </div>
    //                             );
    //                         })}
    //                     </div>

    //                     {formData?.approvals?.clerk ? (
    //                         <div className='approvedLabel' style={{ marginTop: '24px' }}>You have already Approved!</div>
    //                     ) : (
    //                         <button className='approveBtn' onClick={handleapproveall} style={{ marginTop: '24px' }}>
    //                             Approve All
    //                         </button>
    //                     )}



    //                     <div className='pdfHead'>
    //                         Approval Status:
    //                     </div>
    //                     <div>
    //                         <div className='StatusGrid'>
    //                             <strong>Accountant:</strong> {formData?.approvals?.accountant ?
    //                                 <span className='approvedLabel'>approved</span>
    //                                 : <span className='rejectedLabel'>not yet approved</span>}
    //                         </div>

    //                         <div className='StatusGrid'>
    //                             <strong>Clerk:</strong> {formData?.approvals?.clerk ?
    //                                 <span className='approvedLabel'>approved</span>
    //                                 : <span className='rejectedLabel'>not yet approved</span>}
    //                         </div>

    //                         <div className='StatusGrid'>
    //                             <strong>Registrar:</strong> {formData?.approvals?.registrar ?
    //                                 <span className='approvedLabel'>approved</span>
    //                                 : <span className='rejectedLabel'>not yet approved</span>}
    //                         </div>

    //                         <div className='StatusGrid'>
    //                             <strong>President:</strong> {formData?.approvals?.president ?
    //                                 <span className='approvedLabel'>approved</span>
    //                                 : <span className='rejectedLabel'>not yet approved</span>}
    //                         </div>
    //                     </div>


    //                     {/* {formData?.approvals?.clerk ?

    //                         <button className='approveBtn' style={{ marginTop: '24px' }}
    //                             onClick={generateCertificate}>
    //                             Generate Certificate
    //                         </button>
    //                         :
    //                         null
    //                     } */}

    //                 </div>



    //             </div>
    //         </div>
    //     </div >
    // )
}

export default ClerkApprovalPage
