import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import { endpoint } from "../constants/constant";
import jsPDF from "jspdf";
import "jspdf-autotable";
import logo from '../assets/HMC_logo.png';
// import {Header} from 


const PaymentReturn = () => {
  const [searchParams] = useSearchParams();
  const [decryptedData, setDecryptedData] = useState("Decrypting...");
  const [error, setError] = useState(null);

  // Extract the 'i' parameter
  const encryptedString = searchParams.get("i");
  const [caseDetails, setCaseDetails] = useState({});
  const [downloadButton, setDownloadButton] = useState(false)
  const [downloadLink, setDownloadLink] = useState('')


  const generatePdf = async () => {
    console.log(caseDetails)
    const doc = new jsPDF();

    // Helper function to draw the border
    const drawBorder = () => {
      doc.setDrawColor(200, 0, 0);
      doc.setLineWidth(0.5);
      doc.rect(10, 10, 190, 277); // Border for the page
    };

    // Draw border for the first page
    drawBorder();

    const logoWidth = 30; // Desired width
    const logoHeight = 0; // Let the library calculate based on aspect ratio

    doc.addImage(logo, 'PNG', 20, 15, logoWidth, logoHeight);
    doc.setFontSize(14);
    doc.setFont("times", "bold");
    doc.text("Acknowledgement Slip", 37, 8, { align: "center" });

    // Right-aligned heading
    doc.setTextColor(200, 0, 0);
    
    doc.setFontSize(16);
    doc.text("Haryana Medical Council", 190, 25, { align: "right" });

    // Right-aligned address block
    doc.setTextColor(0, 0, 0);
    doc.setFont("times", "italic");
    doc.setFontSize(12);
    doc.text("SCO-410 2nd Floor, Sector-20, Panchkula-134116", 190, 30, { align: "right" });
    doc.text("Phone: 0172-4090410, 0172-2520165", 190, 35, { align: "right" });
    doc.text("Email: registrarhmc@gmail.com, info@haryanamedicalcouncil.org", 190, 40, { align: "right" });
    // doc.line(marginX, 42, pageWidth - marginX, 42);

    // Dynamic acknowledgment heading with date on the same line
    const currentDate = new Date().toLocaleDateString();
    const acknowledgmentText = "Submitted on";
    const acknowledgmentX = 145; // X-coordinate for the start of "Acknowledgment"
    const dateX = acknowledgmentX + doc.getTextWidth(acknowledgmentText) + 7; // Position date slightly after acknowledgment text

    doc.setFont("times", "bold");
    doc.setFontSize(14);
    doc.setTextColor(0, 0, 0); // Acknowledgment in black
    const acknowledgmentY = 55; // Adjust the Y-coordinate for placement
    doc.text(acknowledgmentText, acknowledgmentX, 8);

    doc.setTextColor(200, 0, 0); // Date in red
    doc.text(` - ${currentDate}`, dateX, 8);

    // Table Data
    const tableData = [
      ["Organization Name", `${caseDetails.organizationName}`],
      ["Name of the Organization Chairman", `${caseDetails.chairman}`],
      ["Name of the Organization Secretary", `${caseDetails.secretary}`],
      ["Phone Number", `${caseDetails.phone_number}`],
      ["Email", `${caseDetails.email}`],
      ["Venue of CME", { content: `${caseDetails.address}`, styles: { fontStyle: "bold" } }],
      ["Theme", { content: `${caseDetails.theme}`, styles: { fontStyle: "bold" } }],
      ["Case ID", `${caseDetails.CRN}`],
      ["CME Start Date to CME End Date", { content: `${caseDetails.start_date} - ${caseDetails.end_date}`, styles: { fontStyle: "bold" } }],
      ["Applied For", `${caseDetails.Applied_For}`],
      ["Mode of CME", `${caseDetails.Mode_of_CME}`],
      ["Payment Amount", `${caseDetails.paymentAmount}`],
      ["Payment Method", `${caseDetails.paymentMode}`],
      ["Transaction ID", `${caseDetails.RefCode}`],
      ["Payment Status", `${caseDetails.paymentstatus}`],
    ];

    doc.autoTable({
      startY: acknowledgmentY, // Start the table a bit lower
      body: tableData,
      styles: {
        halign: "left",
        valign: "middle",
        lineWidth: 0.05,
        lineColor: [0, 0, 0],
        cellPadding: 1,
      },
      headStyles: {
        fillColor: [200, 0, 0],
        textColor: [255, 255, 255],
      },
      tableLineWidth: 0.1,
      tableLineColor: [0, 0, 0],
    });


    doc.setFontSize(11);
    // Remarks section
    let finalY = doc.lastAutoTable.finalY + 10;
    doc.setFont("times", "normal");
    doc.setTextColor(200, 0, 0);
    doc.text("Remarks:", 15, finalY);
    doc.setTextColor(0, 0, 0);
    
    doc.text(
      `Please mention the case if ${caseDetails.CRN} for any future correspondences with reference to this application or use it to check the status of your application online.`,
      15,
      finalY + 5,
      { maxWidth: 175 }
    );

    // Note section
    finalY += 20;
    doc.setTextColor(200, 0, 0);
    doc.text("Note:", 15, finalY);
    doc.setTextColor(0, 0, 0);
    doc.text(
      "You are requested to submit hard copies (self-attested) of documents as per the checklist along with a copy of acknowledgment to Haryana Medical Council office 30 days prior to the Date of CME for processing your application. If documents are not received within the time period, your application may be rejected, and no claim for a refund of fees will be entertained.",
      15,
      finalY + 5,
      { maxWidth: 175 }
    );

    // Documents checklist
    finalY += 20;
    const checklist = [
      'Registration Number of Association / Organization with evidence',
      'List of the Faculty with detailed qualification and experience',
      'Detailed programme schedule mentioning time, subject and the name of the Speaker',
      'Medical Registration Certificate of the Organizing Chairman',
      'Medical Registration Certificate of the Organizing Secretary',
      'Any Other Supporting Documents',
    ];

    const checklistTable = checklist.map((item, index) => [index + 1, item]);

    doc.setFontSize(16);
    doc.setTextColor(200, 0, 0);


    // If the content overflows, add a new page
    //if (finalY > 277) {
    //  doc.addPage();
      // Draw the border on the new page
      //drawBorder();
      // Reset finalY for the new page
      // finalY = 20;
    //}


    doc.text("Documents to be submitted in Haryana Medical Council office as per checklist", 15, finalY+10);

    finalY += 15;
    // Add the checklist table only once
    doc.autoTable({
      startY: finalY,
      body: checklistTable,
      head: [["S.No.", "Description"]],
      styles: {
        halign: "left",
        valign: "middle",
        lineWidth: 0.1,
        lineColor: [0, 0, 0],
        cellPadding: 1,
      },
      headStyles: {
        fillColor: [200, 0, 0],
        textColor: [255, 255, 255],
      },
      tableLineWidth: 0.1,
      tableLineColor: [0, 0, 0],
    });


    const pdfData = doc.output("blob"); // Get the PDF as a blob object
    const pdfUrl = URL.createObjectURL(pdfData); // Create a temporary URL for the blob

    const CRN = caseDetails?.CRN
    const formData = new FormData();
    formData.append("Ack", pdfData); // Append the item ID
    formData.append("CRN", CRN); // Append the item ID

    try {
      const response = await axios.post(`${endpoint}/saveacknowldgement`, formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            // 'Authorization': `Bearer ${token}`
          }
        }
      )
      if (response.data.AckURL) {
        setDownloadButton(true)
        setDownloadLink(`${response.data.AckURL}`)
      } else {

      }
    } catch (error) {
      console.error("Error uploading pdf:", error);
    }

    // window.open(pdfUrl, "_blank"); // Open the PDF in a new tab
  };

  useEffect(() => {
    if (encryptedString) {
      // Send the encrypted string to the backend for decryption
      console.log(encryptedString);
      const fetchDecryptedData = async () => {
        try {
          const response = await axios.post(`${endpoint}/paymentstatus`, {
            encryptedString,
          });
          const respStatus = response.data;

          console.log(respStatus.formdata);

          // Extract formdata details
          const {
            organizationName,
            email,
            phone_number,
            address,
            theme,
            registrationNumber,
            cmeStartDate,
            cmeEndDate,
            daysDifference,
            delegates,
            exercise,
            hours,
            chairman,
            secretary,
            methodology,
            pdfURL,
            approvals,
            alldocsverified,
            time,
            date,
            certificateGenerated,
            CRN,
            isGovernmentOrganisation,
            emailVerified,
            uploadedCertificateURL,
            isInternationalFaculty,
            isCmeOffline,
            caseId,
            paymentData,
          } = respStatus?.formdata;

          // Format dates and determine details
          const formatDate = (inputDate) => {
            const [year, month, day] = inputDate.split("-");
            return `${day}-${month}-${year}`;
          };

          const start_date = formatDate(cmeStartDate);
          const end_date = formatDate(cmeEndDate);

          const Mode_of_CME = isCmeOffline ? "Offline" : "Online";
          const Applied_For = isInternationalFaculty ? "International" : "National";

          const paymentstatus = paymentData.RMK;
          const paymentDate = paymentData.TET;
          const paymentAmount = paymentData.AMT
          const paymentMode = paymentData.PMD
          const RefCode = paymentData.BRN

          // Set case details
          setCaseDetails((prevState) => ({
            ...prevState,
            organizationName,
            email,
            phone_number,
            address,
            theme,
            registrationNumber,
            start_date,
            end_date,
            daysDifference,
            delegates,
            exercise,
            hours,
            chairman,
            secretary,
            methodology,
            pdfURL,
            approvals,
            alldocsverified,
            time,
            date,
            certificateGenerated,
            CRN,
            isGovernmentOrganisation,
            emailVerified,
            uploadedCertificateURL,
            isInternationalFaculty,
            isCmeOffline,
            caseId,
            paymentstatus,
            paymentDate,
            Mode_of_CME,
            Applied_For,
            paymentAmount, paymentMode, RefCode
          }));

          // Success or failure-specific actions
          if (paymentData.RMK === "Success") {
            // setDecryptedData({
            //   Msg: "Payment Completed. Redirecting...",
            //   bool: true,
            // });

            // Redirect after 2 seconds
            // setTimeout(() => {
            //   window.location.href = "https://cme.haryanamedicalcouncil.org/";
            // }, 2000);
          } else {
            setDecryptedData({
              Msg: "Payment failed, please use your case ID sent on mail to check the status.",
              bool: false,
            });
          }
        } catch (err) {
          console.error("Error while decrypting:", err);
          setError("Failed to decrypt the data.");
        }
      };

      fetchDecryptedData();
    } else {
      setDecryptedData("No encrypted string found.");
    }
  }, [encryptedString]);


  useEffect(() => {
    console.log(caseDetails)
    generatePdf();

  }, [caseDetails])


  const redirect = () => {
    window.location.href = "https://cme.haryanamedicalcouncil.org/";
  };

  // Inline styles
  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      // justifyContent: "center",
      height: "100vh",
      backgroundColor: "#f9fafb",
      fontFamily: "Arial, sans-serif",
      textAlign: "center",
    },
    header: {
      backgroundColor: "#031d60",
      color: "white",
      padding: "20px 0",
      textAlign: "center",
      fontSize: "24px",
      fontWeight: "bold",
      width: "100%",
      boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
    },
    buttonContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      gap: "20px", // Space between buttons
      marginTop: "20px",
    },
    button: {
      padding: "12px 25px",
      fontSize: "16px",
      color: "#fff",
      backgroundColor: "#4CAF50",
      border: "none",
      borderRadius: "5px",
      cursor: "pointer",
      boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
      transition: "background-color 0.3s ease, transform 0.2s ease",
    },
    buttonHover: {
      backgroundColor: "#45a049",
    },
    secondaryButton: {
      padding: "12px 25px",
      fontSize: "16px",
      color: "#fff",
      backgroundColor: "#007BFF",
      border: "none",
      borderRadius: "5px",
      cursor: "pointer",
      boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
      transition: "background-color 0.3s ease, transform 0.2s ease",
    },
    secondaryButtonHover: {
      backgroundColor: "#0056b3",
    },
    loadingText: {
      color: "#666",
      fontSize: "18px",
      marginTop: "20px",
    },
  };

  return (
    <div style={styles.container}>
      {/* Header */}
      <div style={styles.header}>Haryana Medical Council</div>

      {/* Conditional Buttons or Loading Text */}
      {downloadButton ? (
        <div style={styles.buttonContainer}>
          {/* Download Button */}
          <button
            style={styles.button}
            onClick={() => window.open(downloadLink, "_blank")}
          >
            Download Acknowledgment Slip
          </button>

          {/* Check Registration Status Button */}
          <button
            style={styles.secondaryButton}
            onClick={() =>
              window.open("https://cme.haryanamedicalcouncil.org/", "_blank")
            }
          >
            Check Registration Status
          </button>
        </div>
      ) : (
        <>
        <div style={styles.loadingText}>Loading Acknowledgment Slip...</div>
        <button 
          style={{
            backgroundColor: 'transparent',
            border: 'none',
           
            top: 0,
            left: 0,
            cursor: 'pointer',
          }} 
          onClick={() => generatePdf()}
        ></button>
      </>
      
      )}
    </div>
  );

};

export default PaymentReturn;
