import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Layout from './Layout';
import Form from './components/Form/Form';
import Homepage from './components/Homepage/Homepage';
import Login from './components/Login/Login';
import ViewStatus from './components/ViewStatus/ViewStatus';
import RegistrarDash from './components/Dashboards/REGISTRAR/RegistrarDash';
import ClerkDash from './components/Dashboards/CLERK/ClerkDash';
import RootDashboard from './components/Dashboards/RootDashboard'
import Payments from './components/Payment'
import PaymentReturn from './components/PaymentReturn'
import Ack from './components/ViewStatus/Ack';

import RequireAuth from './components/RequireAuth';
import ClerkApprovalPage from './components/Dashboards/CLERK/ClerkApprovalPage'
import PresidentApprovalPage from './components/Dashboards/PresidentApprovalPage'
import RegistrarApprovalPage from './components/Dashboards/REGISTRAR/RegistrarApprovalPage'
import AccountantApprovalPage from './components/Dashboards/ACCOUNTANT/AccountantApprovalPage'
import CertificatePage from './components/Dashboards/CLERK/CertificatePage';
import './App.css'

function App() {

  return (
    <>
      <Routes>

        <Route path="/" element={<Layout />}>

          <Route index element={<Homepage />} />
          <Route path="/Form" element={<Form />} />
          <Route path="/Login" element={<Login />} />
          <Route path="/payments" element={<Payments />} />
          <Route path="/viewregistrationstatus" element={<ViewStatus />} />

          <Route element={<RequireAuth />}>
            <Route path="/dashboard/new" element={<RootDashboard />} />
            <Route path="/dashboard/previous" element={<RootDashboard />} />
            <Route path="/dashboard/certificategeneration" element={<RootDashboard />} />
            <Route path="/dashboard/certificategenerationPage/:_id" element={<CertificatePage />} />

            <Route path="/dashboard/clerk/:_id" element={<ClerkApprovalPage />} />
            <Route path="/dashboard/president/:_id" element={<PresidentApprovalPage />} />
            <Route path="/dashboard/registrar/:_id" element={<RegistrarApprovalPage />} />
            <Route path="/dashboard/accountant/:_id" element={<AccountantApprovalPage />} />


          </Route>
        </Route>
        <Route path="/paymentreturn" element={<PaymentReturn />} />
        <Route path="/ack" element={<Ack />} />

      </Routes>
    </>
  );
}

export default App;